import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import Testimonials from './components/Testimonials';
import './App.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="App">
      <Header />
      <main>
        <section className="hero">
          <h1 className="main-heading">Understand Your Money, The Right Way With AI!</h1>
          <p className="subtext">
            Discover insights about your finances you never knew and optimize your spending with Dollar Detective—we only need your bank statement!
          </p>
          <button className="cta-button-hero" onClick={() => setIsModalOpen(true)}>
            Get Started <span className="arrow">→</span>
          </button>
        </section>
        <Elements stripe={stripePromise}>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </Elements>
        <Testimonials />
      </main>
      <section className="cta-section">
        <div className="cta-content">
          <h2 className="cta-heading">Ready to Unlock Your Financial Potential?</h2>
          <p className="cta-subtext">
            Join satisfied users who have transformed their financial lives with Dollar Detective.
          </p>
          <button className="cta-button-hero" onClick={() => setIsModalOpen(true)}>
            Analyze Your Finances Now <span className="arrow">→</span>
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;