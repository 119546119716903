import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <a href="/" className="logo" style={{ textDecoration: 'none' }}>Dollar detective.</a>
    </header>
  );
}

export default Header;