import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { extractText } from '../utils/pdfUtils';

function Modal({ isOpen, onClose, showPayment = false, onPaymentSuccess }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const modalRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const extractedText = await extractText(new Uint8Array(e.target.result));
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/analyze-statement`, { text: extractedText });
        localStorage.setItem('analysisResults', JSON.stringify(response.data));
        window.location.href = '/results';
      } catch (error) {
        console.error('Error in analysis:', error);
        alert('We are experiencing a lot of traffic right now. Please try again later.');
        setIsLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleClose = () => {
    setFile(null);
    setIsLoading(false);
    onClose();
    setFileName('');
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePayment = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessingPayment(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('Error:', error);
        alert('Payment failed: ' + error.message);
        setIsProcessingPayment(false);
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/process-payment`, { paymentMethodId: paymentMethod.id });

      if (response.data.clientSecret) {
        const result = await stripe.confirmCardPayment(response.data.clientSecret);
        if (result.error) {
          console.error('Stripe confirmation error:', result.error);
          alert(`Payment failed: ${result.error.message}`);
        } else if (result.paymentIntent.status === 'succeeded') {
          onPaymentSuccess();
          handleClose();
        }
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      alert(`Payment failed: ${error.message}`);
    }
    setIsProcessingPayment(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <button className="close-button" onClick={handleClose}>&times;</button>
        {isLoading ? (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Analyzing your statement...</p>
          </div>
        ) : showPayment ? (
          <div className="payment-container">
            <h2>Unlock Full Results</h2>
            <p>Pay $2.99 to view your complete financial analysis.</p>
            <form onSubmit={handlePayment}>
              <div className="card-element-container">
                <CardElement />
              </div>
              <button 
                className="modal-button payment-button" 
                type="submit" 
                disabled={isProcessingPayment}
              >
                {isProcessingPayment ? 'Processing...' : 'Pay $2.99'}
              </button>
            </form>
          </div>
        ) : (
          <div className="upload-container">
            <h2>Upload Your Bank Statement</h2>
            <p>Get started by uploading your bank statement in PDF format.</p>
            <label className={`file-input-label ${fileName ? 'file-selected' : ''}`}>
              <input 
                type="file" 
                accept="application/pdf" 
                onChange={handleFileChange} 
                style={{ display: 'none' }}
              />
              {fileName ? fileName : 'Choose File'}
            </label>
            <button 
              className="modal-button upload-button" 
              onClick={handleFileUpload} 
              disabled={!file}
            >
              Analyze Statement
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;