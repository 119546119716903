import React from 'react';

function Testimonials() {
  const testimonials = [
    { text: "Dollar Detective provided me with invaluable insights into my spending habits, helping me save $300 in just the first month!", author: "John" },
    { text: "I was shocked to discover the unnecessary charges I was paying. Dollar Detective opened my eyes to my financial leaks!", author: "Jane" },
    { text: "The insights I gained from Dollar Detective are worth their weight in gold. It should cost twice as much for the value it delivers!", author: "Mike" },
    { text: "Absolutely transformative. I uncovered hidden savings and gained a clearer picture of my finances.", author: "Sarah" },
    { text: "Using Dollar Detective was the best financial decision I've made this year. It completely changed my approach to budgeting!", author: "Chris" },
    { text: "If you're not leveraging Dollar Detective, you're missing out on critical insights that can lead to significant savings!", author: "Jessica" },
    { text: "I never realized how much I could save until I used Dollar Detective. It's an essential tool for anyone serious about their finances.", author: "David" },
    { text: "Every dollar spent on Dollar Detective has paid off. My financial habits have improved dramatically thanks to the insights I've gained.", author: "Emily" },
  ];

  return (
    <section className="testimonials">
      <h2 className="testimonials-title">What are people saying about Dollar Detective?</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <div className="testimonial-content">
              <p className="testimonial-text">"{testimonial.text}"</p>
              <p className="testimonial-author">- {testimonial.author}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;